import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Sppinner from "../Animations/Sppinner";

const AddNewTeacher = ({ cancel }) => {
  const [teacherData, setTeacherData] = useState({});
  const [animation, setAnimation] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTeacherData({ ...teacherData, [name]: value });
  };

  const sendData = async (e) => {
    e.preventDefault();
    const {
      name,
      father_Name,
      mother_Name,
      mobileNo,
      email,
      dob,
      gender,
      qualification,
      address,
      teacher_Image,
    } = teacherData;

    if (
      !name ||
      !father_Name ||
      !mother_Name ||
      !mobileNo ||
      !email ||
      !dob ||
      !gender ||
      !address
    ) {
      return toast.warn("Enter all details");
    }
    if (!teacher_Image) {
      return toast.warn("Upload Image");
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("father_Name", father_Name);
    formData.append("mother_Name", mother_Name);
    formData.append("gender", gender);
    formData.append("mobileNo", mobileNo);
    formData.append("email", email);
    formData.append("dob", dob);
    formData.append("qualification", qualification);
    formData.append("address", address);
    formData.append("profile_Image", teacher_Image);
    try {
      setAnimation(true);
      const { data } = await axios.post("/v1/auth/teacher/register", formData);
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        setPopUp(true);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      setAnimation(false);
    }
  };

  return (
    <>
      <div className="new-teacher-comp">
        {animation ? (
          <Sppinner />
        ) : (
          <form>
            <h2>New Teacher Form</h2>
            <div>
              <input
                type="text"
                placeholder="Teacher Name"
                name="name"
                value={teacherData.name}
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Father Name"
                name="father_Name"
                value={teacherData.father_Name}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Mother Name"
                name="name"
                value={teacherData.mother_Name}
              />
            </div>
            <div style={{ display: "inline" }}>
              <label htmlFor="" style={{ display: "inline" }}>
                Gender
              </label>
            </div>
            <span>
              <label htmlFor="male" style={{ marginLeft: "50px" }}>
                Male
              </label>
              <input
                type="radio"
                name="gender"
                id="male"
                value={teacherData.dob}
                style={{
                  margin: "20px 5px",
                  padding: "10px",
                  display: "inline",
                }}
              />
            </span>
            <span>
              <label htmlFor="female" style={{ marginLeft: "50px" }}>
                Female
              </label>
              <input
                type="radio"
                name="gender"
                id="female"
                style={{
                  margin: "10px 5px",
                  padding: "20px",
                  display: "inline",
                }}
              />
            </span>

            <div>
              <label htmlFor="">Date of Birth</label>
              <input
                type="date"
                name="dob"
                value={teacherData.dob}
                max={"2024-04-01"}
              />
            </div>
            <div>
              <input
                type="number"
                placeholder="Mobile Number"
                name="mobileNo"
                value={teacherData.mobileNo}
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email ID"
                name="email_Id"
                value={teacherData.email_Id}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Educational Qualification"
                name="qualification"
                value={teacherData.qualification}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Address"
                name="Address"
                value={teacherData.address}
              />
            </div>
            <div>
              <label htmlFor="">Upload teacher Photo</label>
              <input type="file" placeholder="Select Course Image" />
            </div>
            <div
              style={{
                paddingTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button className="btn" style={{ background: "blue" }} onclick={sendData}>
                <span>Register</span>
                <i
                  className="fa-solid fa-arrow-up-right-from-square"
                  style={{ marginLeft: "8px" }}
                />
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default AddNewTeacher;
