import React from "react";
import "../assets/css/components.css";
import img1 from "../assets/img/bg1.jpg";
import img2 from "../assets/img/bg2.avif";

const UserProfile = ({ user }) => {
  const base_URL = process.env.REACT_APP_API;
  const imgPath = `${base_URL}/${user?.profile_Image}`;
  return (
    <>
      <div className="user-profile-comp">
        <div className="img">
          <img src={img1} alt="cover-img" className="cover-img" />
          <img
            src={`${user?.profile_Image ? imgPath : img2}`}
            alt="profile-img"
            className="profile-img"
          />
        </div>
        <div className="profile-details">
          <h2>Personal Details</h2>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <p>ID</p>
              <p>Name</p>
              <p>Father Name</p>
              <p>Mother Name </p>
              <p>Gender </p>
              <p>DOB </p>
              <p>Email Id </p>
              <p>Mobile No. </p>
              <p>Address</p>
            </div>
            <div>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div style={{ color: "blue" }}>
              <p>{user?.teacherID ? user?.teacherID : user?.studentID}</p>
              <p>{user?.name ? user?.name : "..."}</p>
              <p>{user?.father_Name ? user?.father_Name : "..."}</p>
              <p>{user?.mother_Name ? user?.mother_Name : "..."}</p>
              <p> {user?.gender ? user?.gender : "..."}</p>
              <p> {user?.dob ? user?.dob : "..."}</p>
              <p>{user?.email ? user?.email : "..."}</p>
              <p> {user?.mobileNo ? "+91 " + user?.mobileNo : "..."}</p>
              <p>{user?.address ? user?.address : "..."}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
