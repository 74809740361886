import React from "react";
import "../assets/css/components.css";

const SearchStudent = ({ data }) => {
  const base_URL = process.env.REACT_APP_API;

  return (
    <>
      <div className="student-details-comp">
        <div className="personal-details">
          <h2>Personal Details</h2>
          <hr />

          <div style={{ display: "flex", padding: "10px 30px" }}>
            <div style={{ width: "30%" }}>
              <p>Student Id</p>
              <p>Name</p>
              <p>Father Name</p>
              <p>Mother Name</p>
              <p>Gender</p>
              <p>Date of Birth</p>
              <p>Email Id</p>
              <p>Mobile No</p>
              <p>Address</p>
            </div>
            <div style={{ width: "40%", color: "blue" }}>
              <p>{data?.studentID}</p>
              <p>{data?.name ? data?.name : "..."}</p>
              <p>{data?.father_Name ? data?.father_Name : "..."}</p>
              <p>{data?.mother_Name ? data?.mother_Name : "..."}</p>
              <p> {data?.gender ? data?.gender : "..."}</p>
              <p> {data?.dob ? data?.dob : "..."}</p>
              <p>{data?.email ? data?.email : "..."}</p>
              <p> {data?.mobileNo ? "+91 " + data?.mobileNo : "..."}</p>
              <p>{data?.address ? data?.address : "..."}</p>
            </div>
            <div style={{ width: "30%", textAlign: "center" }}>
              <img
                src={`${base_URL}/${data?.profile_Image}`}
                alt="Student_Photo"
                style={{
                  width: "90%",
                  height: "250px",
                  border: "3px solid darkblue",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="course-details">
          <h2>Course Details</h2>
          <hr />

          <table border={"2px solid black"}>
            <tr>
              <th>Course Code</th>
              <th>Course Name</th>
              <th>Enrolled Date</th>
            </tr>
            {data?.enrolled_Courses?.map((item) => (
              <tr key={item?.course_Code}>
                <td>{item?.course_Code}</td>
                <td>{item?.course_Title}</td>
                <td>{item?.enrolled_Date.split("T")[0]}</td>
              </tr>
            ))}
          </table>
        </div>
        <div className="course-details">
          <h2>Fee Details</h2>
          <hr />

          <div style={{ display: "flex", padding: "10px 30px" }}>
            <div style={{ width: "40%" }}>
              <p>Enrolled Courses</p>
              <p>Name</p>
              <p>Father Name</p>
              <p>Mother Name</p>
            </div>
            <div style={{ width: "60%", color: "blue" }}>
              <p>AS1278917382739</p>
              <p>Satish Kumar Maurya</p>
              <p>Dharm Raj Maurya</p>
              <p>Maya Devi</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchStudent;
