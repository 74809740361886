import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import Sppinner from "../Animations/Sppinner";
import { Helmet } from "react-helmet";

const Courses = () => {
  const [Animation, setAnimation] = useState(true);
  const [allCourseData, setAllCourseData] = useState([]);
  const base_URL = process.env.REACT_APP_API;

  const getCourseData = async () => {
    try {
      const { data } = await axios.get("/v1/course/all-course-details");
      if (data?.success) {
        setAllCourseData(data?.details);
        setAnimation(false);
      }
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
      toast.error("Something went wrong!!");
    }
  };
  useEffect(() => {
    getCourseData();
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Courses</title>
        <meta
          name="keywords"
          content="Rajediha, computer center, training, computer course, courses, adca, dca, ccc, html, css, web dev, ms-word, tally, o level, a level"
        />
      </Helmet>
      <div style={{ minHeight: "70vh", background: "lightgray" }}>
        <div
          className="all-courses-details"
          style={{ background: "lightgray" }}
        >
          {Animation ? (
            <Sppinner />
          ) : (
            allCourseData?.map((item) => (
              <div className="course-card" key={item?._id} data-aos="fade-up">
                <div>
                  <img
                    src={`${base_URL}/${item?.course_Image}`}
                    alt="course-img"
                  />
                </div>
                <div className="card-contents">
                  <h2
                    style={{
                      textAlign: "center",
                      color: "darkblue",
                      textTransform: "uppercase",
                    }}
                  >
                    <span> {item?.course_Title}</span>
                  </h2>

                  <h4>
                    Course Duration :{" "}
                    <span>{item?.course_Duration} months</span>
                  </h4>
                  <h4>
                    Course Fees :{" "}
                    <i
                      className="fa-solid fa-indian-rupee-sign"
                      style={{ marginRight: "4px" }}
                    />
                    <span>{item?.course_Fee}</span>
                  </h4>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Courses;
