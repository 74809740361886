import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../ContextApi/authContext";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { toast } from "react-toastify";
import userProfile from "../assets/img/bg.jpg";

const SideDrawer = () => {
  const [verify, setverify] = useState(false);
  const [auth, setAuth] = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const imgPath = `${process.env.REACT_APP_API}/${auth?.user?.profile_Image}`;
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("ACCSATDEV_USER-INFO");
    setAuth({
      user: null,
      token: "",
    });
    setTimeout(() => {
      toast.success("Successfully Logout!!");
    }, 500);

    navigate("/account-login");
  };

  return (
    <>
      <>
        <button
          onClick={toggleDrawer}
          style={{
            color: "white",
            fontSize: "25px",
            padding: "5px 10px",
            margin: "10px",
            background: "transparent",
            border: "none",
          }}
        >
          {isOpen ? (
            <i className="fa-solid fa-close" />
          ) : (
            <i className="fa-solid fa-bars" />
          )}
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          className="bla bla bla"
          size={250}
        >
          <div
            style={{
              background: "rgb(8, 75, 130)",
              height: "100%",
              width: "400px",
              padding: "50px 20px",
            }}
          >
            <div
              id="acc-navbar-right"
              style={{ flexDirection: "column", width: "60%" }}
            >
              <li>
                <NavLink to="/courses">Courses</NavLink>
              </li>

              <li>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li>
                <div
                  className="nav-verification"
                  onClick={() => setverify(!verify)}
                >
                  Verification
                  <i
                    className={`fas fa-caret-${verify ? "up" : "down"}`}
                    style={{ marginLeft: "5px" }}
                  />
                </div>
                <div style={{ display: `${verify ? "block" : "none"}` }}>
                  <NavLink
                    to="/marksheet-verification"
                    onClick={() => setverify(false)}
                  >
                    Marksheet Verification
                  </NavLink>
                  <NavLink
                    to="/certificate-verification"
                    onClick={() => setverify(false)}
                  >
                    Certificate Verification
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink to="/exam-results">Results</NavLink>
              </li>

              {auth?.user ? (
                <li style={{ zIndex: "23300000000000000" }}>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          {...bindTrigger(popupState)}
                          style={{ padding: " 5px 57px", margin: "10px" }}
                        >
                          <img
                            src={
                              auth?.user?.profile_Image ? imgPath : userProfile
                            }
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              border: "2px solid white",
                            }}
                            title="User Dashboard"
                            alt="profile"
                          />
                        </Button>
                        <Menu
                          {...bindMenu(popupState)}
                          style={{ zIndex: "90000000" }}
                        >
                          <MenuItem onClick={popupState.close}>
                            <li>
                              <NavLink
                                to={`/dashboard/v1/${auth?.user?.role}`}
                                style={{ textDecoration: "none" }}
                              >
                                Dashboard{" "}
                                <i
                                  className="fas fa-dashboard"
                                  style={{ marginLeft: "4px" }}
                                />
                              </NavLink>
                            </li>
                          </MenuItem>
                          <MenuItem onClick={popupState.close}>
                            <NavLink
                              onClick={handleLogout}
                              style={{ textDecoration: "none" }}
                            >
                              Logout
                              <i
                                className="fas fa-right-from-bracket"
                                style={{ marginLeft: "6px" }}
                              />
                            </NavLink>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </li>
              ) : (
                <li>
                  <NavLink to="/account-login">Login</NavLink>
                </li>
              )}
            </div>
          </div>
        </Drawer>
      </>
    </>
  );
};

export default SideDrawer;
