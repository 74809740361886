import React, { useEffect, useState } from "react";
import AddNewCourse from "../../../Components/AddNewCourse";
import "../../../assets/css/admin.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../../ContextApi/authContext";

const CourseDetails = () => {
  const [auth] = useAuth();
  const [addCourse, setAddCourse] = useState(false);
  const [allCourseData, setAllCourseData] = useState([]);
  const base_URL = process.env.REACT_APP_API;

  const getCourseData = async () => {
    try {
      const { data } = await axios.get("/v1/course/all-course-details");
      if (data?.success) {
        setAllCourseData(data?.details);
      }
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
      toast.error("Something went wrong!!");
    }
  };
  useEffect(() => {
    getCourseData();
  }, []);
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="course-details-page"
          style={{ opacity: `${addCourse ? "0.01" : "1"}` }}
        >
          <div className="add-new-course">
            <div style={{ textAlign: "right" }}>
              <button
                className="btn"
                style={{ background: "blue", width: "300px", height: "60px" }}
                onClick={() => {
                  setAddCourse(true);
                }}
              >
                Add New Course
                <i
                  className="fa-solid fa-arrow-up-right-from-square"
                  style={{ marginLeft: "8px" }}
                />
              </button>
            </div>
          </div>
          <div className="all-courses-details">
            {allCourseData?.map((item) => (
              <div className="course-card" key={item?._id}>
                <Link
                  to={`/dashboard/v1/${auth?.user?.role}/course-details/${item?.course_Title}/${item?.course_Code}/${item?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div>
                    <img
                      src={`${base_URL}/${item?.course_Image}`}
                      alt="course-img"
                    />
                  </div>
                  <div className="card-contents">
                    <h2
                      style={{
                        textAlign: "center",
                        color: "darkblue",
                        textTransform: "uppercase",
                      }}
                    >
                      <span> {item?.course_Title}</span>
                    </h2>

                    <h4>
                      Course Duration :{" "}
                      <span>{item?.course_Duration} months</span>
                    </h4>
                    <h4>
                      Course Fees :{" "}
                      <i
                        className="fa-solid fa-indian-rupee-sign"
                        style={{ marginRight: "4px" }}
                      />
                      <span>{item?.course_Fee}</span>
                    </h4>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: `${addCourse ? "block" : "none"}`,
          }}
        >
          <AddNewCourse setAddCourse={setAddCourse} />
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
