import React, { useState } from "react";
import "../../../assets/css/admin.css";
import { useAuth } from "../../../ContextApi/authContext";
import { toast } from "react-toastify";
import axios from "axios";

const PasswordChange = () => {
  const [auth] = useAuth();
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const role = auth?.user?.role === "student" ? "student" : "teacher";

  const updatePassword = async (e) => {
    e.preventDefault();
    if (!password || !cpassword) {
      toast.warn("Enter Password and Confirm Password");
      return;
    }
    if (password !== cpassword) {
      toast.warn("Enter Password and Confirm Password not match!");
      return;
    }
    try {
      const { data } = await axios.put(
        `/v1/auth/${role}/profile-update/${auth?.user?._id}`, {password}
      );
      if (data?.success) {
        toast.success("Password successfully updated");
        setPassword("");
        setCPassword("");
        return;
      }
      toast.error("Can't Updated");
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
      toast.error("Something went wrong!!");
    }
  };
  return (
    <>
      <div className="password-change-page">
        <h2>Change Password</h2>
        <form>
          <div>
            <input
              type="text"
              placeholder="Enter New Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Re-enter New Password"
              onChange={(e) => setCPassword(e.target.value)}
              value={cpassword}
            />
          </div>
          <div
            className="action-btn"
            style={{
              textAlign: "right",
              paddingTop: "10px",
            }}
          >
            <button
              className="btn"
              style={{ background: "blue", width: "100%" }}
              onClick={updatePassword}
            >
              Change Password
              <i
                className="fa-solid fa-arrow-up-right-from-square"
                style={{ marginLeft: "6px" }}
              />{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PasswordChange;
