import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/account.css";
import { toast } from "react-toastify";
import { useAuth } from "../../ContextApi/authContext";
import Sppinner from "../../Animations/Sppinner";
import axios from "axios";
import { Helmet } from "react-helmet";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setuserId] = useState();
  const [password, setPassword] = useState();
  const [teacher, setTeacher] = useState(false);
  const [auth, setAuth] = useAuth();
  const [animation, setAnimation] = useState(false);
  const navigate = useNavigate();

  const sendData = async (e) => {
    e.preventDefault();
    if (!userId || !password) {
      return toast.warn("Enter mobile number and password.");
    }
    try {
      setAnimation(true);
      const { data } = await axios.post(
        `v1/auth/${teacher ? "teacher" : "student"}/login`,
        { userId, password }
      );
      setAnimation(false);

      if (data?.success) {
        setAuth({ ...auth, user: data?.details, token: data?.token });
        localStorage.setItem("ACCSATDEV_USER-INFO", JSON.stringify(data));

        // Redirect to Dashboard Page //
        navigate(`/dashboard/v1/${data?.details?.role}`);
        setTimeout(() => {
          toast.success(data?.message);
        }, 1000);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      setAnimation(false);
      toast.error("Something went wrong");
      console.log("Error In login " + error);
    }
  };
  return (
    <Layout>
      <Helmet>
        <title>Account Login</title>
        <meta
          name="keywords"
          content="Rajediha, computer center, training, computer course, sant kabir nagar, Santha "
        />
      </Helmet>
      <div className="account_page">
        {animation ? (
          <Sppinner />
        ) : (
          <form>
            <h2>Account Login </h2>
            <div className="userId">
              <input
                type="number"
                id="userId"
                maxLength={10}
                minLength={10}
                placeholder="User Id"
                onChange={(e) => setuserId(e.target.value)}
                value={userId}
              />
            </div>

            <div className="password">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                style={{ textTransform: "normal" }}
              />
            </div>
            <div>
              <p
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle"
              >
                {showPassword ? "Hide Password" : "Show Password"}
              </p>
            </div>
            <div style={{ alignItems: "center", display: "flex" }}>
              <input
                type="checkbox"
                onClick={(e) => {
                  setTeacher(e.target.checked);
                }}
                style={{ width: "30px", height: "20px" }}
              />
              <p style={{ fontWeight: "550" }}>Login as Teacher</p>
            </div>

            <div className="form_btn">
              {/* <button
                className="btn reset_btn"
                onClick={(e) => {
                  e.preventDefault();
                  setuserId("");
                  setPassword("");
                }}
              >
                Reset
              </button> */}
              <button
                className="btn register_btn"
                onClick={sendData}
                style={{ width: "100%" }}
              >
                Login{" "}
                <i
                  className="fa-solid fa-arrow-up-right-from-square"
                  style={{ marginLeft: "6px" }}
                />
              </button>
            </div>

            <div className="forgot_password">
              <Link
                to="/account/forgot-password"
                style={{
                  textDecoration: "none",
                  fontSize: "17px",
                  marginLeft: "0px",
                  color: "darkblue",
                }}
              >
                Forgot Password
              </Link>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default Login;
