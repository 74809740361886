import React from "react";
import "../assets/css/components.css";
import idCard from "../assets/img/id_card.png";

const StudentIdCard = ({ data }) => {
  const base_URL = process.env.REACT_APP_API;
  return (
    <>
      <div className="student-id-card-comp">
        <div className="card-img">
          <img src={idCard} alt="Student_Id_card" />
        </div>
        <div className="student-img">
          <img src={`${base_URL}/${data?.student_Image}`} alt="" />
        </div>
        <div className="student-details">
          <p>{data?.name ? data?.name : "..."}</p>
          <p>{data?.enrolled_Course ? data?.enrolled_Course : "..."}</p>
          <p>{data?.dob ? data?.dob : "..."}</p>
          <p>{data?.mobileNo ? data?.mobileNo : "..."}</p>
        </div>
        <div className="student_id"> Student ID : {data?.studentID}</div>
      </div>
    </>
  );
};

export default StudentIdCard;
