import Home from "./Pages/home/Home";
import Result from "./Pages/Result";
import Courses from "./Pages/Courses";
import About from "./Pages/about/About";
import Login from "./Pages/auth/Login";
import Error from "./layouts/Error";
import AdminDashboard from "./Pages/Dashboard/Admin/AdminDashboard";
import AdminReport from "./Pages/Dashboard/Admin/AdminReport";
import NewAdmission from "./Pages/Dashboard/Admin/NewAdmission";
import StudentDetails from "./Pages/Dashboard/Admin/StudentDetails";
import CourseDetails from "./Pages/Dashboard/Admin/CourseDetails";
import SingleCourseDetails from "./Pages/Dashboard/Admin/SingleCourseDetails";
import GenReportCard from "./Pages/Dashboard/Admin/GenReportCard";
import GenCertificate from "./Pages/Dashboard/Admin/GenCertificate";
import Teachers from "./Pages/Dashboard/Admin/Teachers";
import UserProfile from "./Components/UserProfile";
import LiveClasses from "./Pages/Dashboard/common/LiveClasses";
import Profile from "./Pages/Dashboard/common/Profile";
import PasswordChange from "./Pages/Dashboard/common/PasswordChange";
import { createBrowserRouter } from "react-router-dom";
import StudentDashboard from "./Pages/Dashboard/Student/StudentDashboard";
import StudentReport from "./Pages/Dashboard/Student/StudentReport";
import EnrolledCourses from "./Pages/Dashboard/Student/EnrolledCourses";
import IdCard from "./Pages/Dashboard/Student/IdCard";
import StudentReportCard from "./Pages/Dashboard/Student/StudentReportCard";
import StudentCertificate from "./Pages/Dashboard/Student/StudentCertificate";
import TeacherDashboard from "./Pages/Dashboard/Teacher/TeacherDashboard";
import TeacherReport from "./Pages/Dashboard/Teacher/TeacherReport";
import CertificateVerification from "./Pages/CertificateVerification";
import MarksheetVerification from "./Pages/MarksheetVerification";

const router = createBrowserRouter([
  {
    id: 1,
    path: "/",
    element: <Home />,
  },
  {
    id: 2,
    path: "/about-us",
    element: <About />,
  },
  {
    id: 3,
    path: "/courses",
    element: <Courses />,
  },
  {
    id: 4,
    path: "/exam-results",
    element: <Result />,
  },
  {
    id: 5,
    path: "/certificate-verification",
    element: <CertificateVerification />,
  },
  {
    id: 6,
    path: "/marksheet-verification",
    element: <MarksheetVerification />,
  },
  {
    id: 7,
    path: "/account-login",
    element: <Login />,
  },
  {
    id: 8,
    path: "/dashboard",
    element: <AdminDashboard />,
    children: [
      {
        id: 1,
        path: "v1/admin",
        element: <AdminReport />,
      },
      {
        id: 2,
        path: "v1/admin/new-admission",
        element: <NewAdmission />,
      },
      {
        id: 3,
        path: "v1/admin/student-details",
        element: <StudentDetails />,
      },
      {
        id: 4,
        path: "v1/admin/course-details",
        element: <CourseDetails />,
      },
      {
        id: 5,
        path: "v1/admin/course-details/:title/:code/:id",
        element: <SingleCourseDetails />,
      },
      {
        id: 6,
        path: "v1/admin/students-report-card",
        element: <GenReportCard />,
      },
      {
        id: 7,
        path: "v1/admin/students-certificate",
        element: <GenCertificate />,
      },
      {
        id: 8,
        path: "v1/admin/live-classes",
        element: <LiveClasses />,
      },
      {
        id: 9,
        path: "v1/admin/teacher-details",
        element: <Teachers />,
      },
      {
        id: 10,
        path: "v1/admin/profile",
        element: <Profile />,
      },
      {
        id: 11,
        path: "v1/admin/:role/profile-details/:id",
        element: <UserProfile />,
      },
      {
        id: 12,
        path: "v1/admin/password-change",
        element: <PasswordChange />,
      },
    ],
  },
  {
    id: 9,
    path: "/dashboard/v1/teacher",
    element: <TeacherDashboard />,
    children: [
      {
        id: 1,
        path: "",
        element: <TeacherReport />,
      },
      {
        id: 2,
        path: "course-details",
        element: <CourseDetails />,
      },
      {
        id: 3,
        path: "course-details/:title/:code/:id",
        element: <SingleCourseDetails />,
      },
      {
        id: 4,
        path: "live-classes",
        element: <LiveClasses />,
      },
      {
        id: 7,
        path: "profile",
        element: <Profile />,
      },
      {
        id: 8,
        path: "password-change",
        element: <PasswordChange />,
      },
    ],
  },
  {
    id: 10,
    path: "/dashboard/v1/student",
    element: <StudentDashboard />,
    children: [
      {
        id: 1,
        path: "",
        element: <StudentReport />,
      },
      {
        id: 2,
        path: "enrolled-courses",
        element: <EnrolledCourses />,
      },
      {
        id: 3,
        path: "live-classes",
        element: <LiveClasses />,
      },
      {
        id: 4,
        path: "student-id-card",
        element: <IdCard />,
      },
      {
        id: 5,
        path: "student-report-card",
        element: <StudentReportCard />,
      },
      {
        id: 6,
        path: "student-certificate",
        element: <StudentCertificate />,
      },
      {
        id: 7,
        path: "profile",
        element: <Profile />,
      },
      {
        id: 8,
        path: "password-change",
        element: <PasswordChange />,
      },
    ],
  },
  {
    id: 11,
    path: "*",
    element: <Error />,
  },
]);

export default router;
