import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "../assets/css/components.css";
import { useAuth } from "../ContextApi/authContext";
import Sppinner from "../Animations/Sppinner";

const UpdateProfile = ({ popUp }) => {
  const [auth] = useAuth();
  const [animation, setAnimation] = useState(false);
  const [userData, setuserData] = useState(auth?.user);

  const role = auth?.user?.role === "student" ? "student" : "teacher";

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setuserData({ ...userData, [name]: value });
  };

  const sendData = async (e) => {
    e.preventDefault();
    const {
      name,
      father_Name,
      mother_Name,
      mobileNo,
      email,
      dob,
      gender,
      qualification,
      address,
      image,
    } = userData;

    if (!name || !mobileNo || !email) {
      return toast.warn("Name, mobile Number and Email can't be empty");
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("father_Name", father_Name);
    formData.append("mother_Name", mother_Name);
    formData.append("gender", gender);
    formData.append("mobileNo", mobileNo);
    formData.append("email", email);
    formData.append("dob", dob);
    formData.append("qualification", qualification);
    formData.append("address", address);
    formData.append("image", image);
    try {
      setAnimation(true);
      const { data } = await axios.put(
        `/v1/auth/${role}/profile-update/${auth?.user?._id}`,
        formData
      );
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        popUp(false);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      setAnimation(false);
      toast.error("Something went wrong!!");
    }
  };

  return (
    <>
      <div className="update-profile-comp">
        {animation ? (
          <Sppinner />
        ) : (
          <form>
            <h2 style={{ textAlign: "center", fontWeight: "600" }}>
              UPDATE PROFILE
            </h2>
            <div>
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={userData?.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Father Name"
                name="father_Name"
                value={userData?.father_Name}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Mother Name"
                name="mother_Name"
                value={userData?.mother_Name}
                onChange={handleChange}
              />
            </div>
            <div style={{ display: "inline" }}>
              <label htmlFor="" style={{ display: "inline" }}>
                Gender
              </label>
            </div>
            <span>
              <label htmlFor="male" style={{ marginLeft: "50px" }}>
                Male
              </label>
              <input
                type="radio"
                name="gender"
                id="male"
                value="male"
                onChange={handleChange}
                style={{
                  margin: "20px 5px",
                  padding: "10px",
                  display: "inline",
                }}
              />
            </span>
            <span>
              <label htmlFor="female" style={{ marginLeft: "50px" }}>
                Female
              </label>
              <input
                type="radio"
                name="gender"
                id="female"
                value="female"
                onChange={handleChange}
                style={{
                  margin: "10px 5px",
                  padding: "20px",
                  display: "inline",
                }}
              />
            </span>

            <div>
              <label htmlFor="">Date of Birth</label>
              <input
                type="date"
                name="dob"
                value={userData?.dob}
                max={"2024-04-01"}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="number"
                placeholder="Mobile Number"
                name="mobileNo"
                value={userData?.mobileNo}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email ID"
                name="email"
                value={userData?.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Educational Qualification"
                name="qualification"
                value={userData?.qualification}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Address"
                name="address"
                value={userData?.address}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="">Upload Profile Photo</label>
              <input
                type="file"
                placeholder="Select Course Image"
                onChange={(e) =>
                  setuserData({ ...userData, image: e.target.files[0] })
                }
              />
            </div>
            <div
              style={{
                paddingTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                className="btn"
                style={{ background: "red" }}
                onClick={(e) => {
                  e.preventDefault();
                  popUp(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                style={{ background: "blue" }}
                onClick={sendData}
              >
                <span>Update</span>
                <i
                  className="fa-solid fa-arrow-up-right-from-square"
                  style={{ marginLeft: "8px" }}
                />
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default UpdateProfile;
