import React, { useRef, useState } from "react";
import Layout from "../layouts/Layout";
import "../assets/css/verification.css";
import Sppinner from "../Animations/Sppinner";
import { toast } from "react-toastify";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Certificate from "../Components/Certificate";
import { Helmet } from "react-helmet";

const CertificateVerification = () => {
  const [animation, setAnimation] = useState(false);
  const [certFound, setcertFound] = useState(false);
  const [certificateNo, setcertificateNo] = useState("");
  const [certData, setcertData] = useState();
  const componentRef = useRef();

  const getCertificate = async (e) => {
    e.preventDefault();
    try {
      if (!certificateNo) {
        return toast.warn("Enter Certificate Number");
      }
      setcertFound(false);
      setAnimation(true);
      const { data } = await axios.get(
        `/v1/certificate/single-certificate-details/${certificateNo}`
      );
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        setcertData(data?.details);
        setcertFound(true)
        return;
      }
      toast.error(data?.message);
    } catch (error) {
      setAnimation(false);
      console.log("Found error in certificate verification : " + error);
      toast.error("Something wen wrong!!");
    }
  };
  return (
    <Layout>
       <Helmet >
        <title>Certificate Verification</title>
        <meta name="keywords" content="Rajediha, computer center, training, computer course, result verification, verification, certificate  verification " />
      </Helmet>
      <div className="verification_page">
        <div className="search-student-id" style={{ paddingTop: "10px" }}>
          <input
            type="text"
            placeholder="Enter Certificate No"
            onChange={(e) => {
              setcertificateNo(e.target.value.toUpperCase());
            }}
            value={certificateNo}
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              width: "300px",
            }}
          />
          <button
            className="btn"
            style={{ background: "green", width: "150px" }}
            onClick={getCertificate}
          >
            Search
          </button>
        </div>
        <div style={{ display: `${animation ? "block" : "none"}` }}>
          <Sppinner />
        </div>

        <div style={{ display: `${certFound ? "block" : "none"}` }}>
          <div className="result_found">
            <div className="cert-no">
              <div>Certificate No  :  {certData?.certificate_No}</div>
              <div>Reg id : {certData?.studentID}</div>
            </div>
            <div className="certificate-details">
              This is certify that Mr./ Miss <span>{certData?.name}</span> s/o,
              d/o <span>{certData?.father_Name}</span>
              and <span>{certData?.mother_Name}</span> has successfully
              completed the course <span>{certData?.course_Title}</span> from{" "}
              <span>{certData?.start_Date}</span> to{" "}
              <span>{certData?.end_Date}</span> and secured{" "}
              <span>{certData?.grade}</span> grade.
            </div>
            <div
              style={{
                color: "darkred",
                fontWeight: "600",
                paddingTop: "20px",
              }}
            >
              Issue Date : {certData?.issued_Date.split("T")[0]}
            </div>
          </div>

          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
            }}
          >
            <h3 style={{ fontWeight: "600" }}>
              Certificate Successfully Verified
            </h3>
            <div>
              <ReactToPrint
                trigger={() => (
                  <button
                    className="btn"
                    style={{ width: "250px", background: "blue" }}
                  >
                    Print Certificate
                    <i
                      className="fa-solid fa-print"
                      style={{ marginLeft: "6px" }}
                    />
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>

        <div style={{ display: "none" }}>
          <Certificate ref={componentRef} data={certData} />
        </div>
      </div>
    </Layout>
  );
};

export default CertificateVerification;
