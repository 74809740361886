import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DeletePopUP = ({ courseId, popUp }) => {
  const navigate = useNavigate();

  const deleteCourse = async () => {
    try {
      const { data } = await axios.delete(
        `/v1/course/delete-course/${courseId}`
      );
      if (data?.success) {
        popUp(false);
        navigate("/dashboard/v1/admin/course-details");
        return toast.success(data?.message);
      }
      return toast.error(data?.message);
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
      toast.error("Something went wrong!!");
    }
  };
  return (
    <div className="popup-box">
      <h3 style={{ fontWeight: "600", textAlign: "center", color: "red" }}>
        You are confirm to delete ?
      </h3>
      <div className="action-btn">
        <button
          className="btn"
          style={{ background: "red", width: "150px" }}
          onClick={deleteCourse}
        >
          <i className="fa-solid fa-trash-can" style={{ marginRight: "6px" }} />{" "}
          Delete
        </button>
        <button
          className="btn"
          style={{ background: "blue", width: "150px" }}
          onClick={() => popUp(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeletePopUP;
