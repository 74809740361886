import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../ContextApi/authContext";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { toast } from "react-toastify";
import SideDrawer from "./Drawer";
import userProfile from "../assets/img/bg.jpg";

const Navbar = () => {
  const [auth, setAuth] = useAuth();
  const [verify, setverify] = useState(false);
  const imgPath = `${process.env.REACT_APP_API}/${auth?.user?.profile_Image}`;
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("ACCSATDEV_USER-INFO");
    setAuth({
      user: null,
      token: "",
    });
    setTimeout(() => {
      toast.success("Successfully Logout!!");
    }, 500);

    navigate("/account-login");
  };
  return (
    <nav>
      <ul className="acc-navbar">
        <div className="acc-navbar-left">
          <li>
            <NavLink to="/" title="GO TO HOME">
              <i className="fas fa-home" style={{ marginRight: "6px" }}></i>
            </NavLink>
          </li>
        </div>

        <div className="acc-navbar-right">
          <li>
            <NavLink to="/courses">Courses</NavLink>
          </li>

          <li>
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <li>
            <div
              className="nav-verification"
              onClick={() => setverify(!verify)}
            >
              Verification
              <i
                className={`fas fa-caret-${verify ? "up" : "down"}`}
                style={{ marginLeft: "5px" }}
              />
            </div>
            <div style={{ display: `${verify ? "block" : "none"}` }}>
              <NavLink
                to="/marksheet-verification"
                onClick={() => setverify(false)}
              >
                Marksheet Verification
              </NavLink>
              <NavLink
                to="/certificate-verification"
                onClick={() => setverify(false)}
              >
                Certificate Verification
              </NavLink>
            </div>
          </li>
          <li>
            <NavLink to="/exam-results">Results</NavLink>
          </li>

          {auth?.user ? (
            <li>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      {...bindTrigger(popupState)}
                      style={{ padding: " 2px 17px" }}
                    >
                      <img
                        src={auth?.user?.profile_Image ? imgPath : userProfile}
                        style={{
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          border: "2px solid white",
                        }}
                        title="User Dashboard"
                        alt="profile"
                      />
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}>
                        <li>
                          <NavLink
                            to={`/dashboard/v1/${auth?.user?.role}`}
                            style={{ textDecoration: "none" }}
                          >
                            Dashboard{" "}
                            <i
                              className="fas fa-dashboard"
                              style={{ marginLeft: "4px" }}
                            />
                          </NavLink>
                        </li>
                      </MenuItem>
                      <MenuItem onClick={popupState.close}>
                        <NavLink
                          onClick={handleLogout}
                          style={{ textDecoration: "none" }}
                        >
                          Logout
                          <i
                            className="fas fa-right-from-bracket"
                            style={{ marginLeft: "6px" }}
                          />
                        </NavLink>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </li>
          ) : (
            <li>
              <NavLink to="/account-login">Login</NavLink>
            </li>
          )}
        </div>

        <div className="side-drawer">
          <SideDrawer />
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
