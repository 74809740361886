import img2 from "../../../assets/img/home-img2.jfif"
import img7 from "../../../assets/img/home-img7.jfif"
import img8 from "../../../assets/img/home-img8.jfif"

const CarouselImg = [
    {
        img : img2,
        name : "Image1"
    },
    {
        img : img7,
        name : "Image1"
    },
    {
        img : img8,
        name : "Image1"
    },

]

export default CarouselImg;