
import vishal from './image/vishal.jpg';
import sapna from './image/sapna.jpg';
import durgesh from './image/durgesh.jpg';
import priti from './image/Priti_photo.jpeg';
import jaya from './image/Jaya_photo.jpeg';
import pankaj from './image/Panakj_photo.jpeg';



const StudentsData = [
    {
        img : vishal,
        name : "Vishal Mani Tripathi",
        course : "O Level"
    },
    {
        img : sapna,
        name : "Sapna Chaudhary",
        course : "O Level"
    },
    {
        img : jaya,
        name : "Jaya",
        course : "O Level"
    },
    {
        img : pankaj,
        name : "Pankaj",
        course : "O Level"
    },
    {
        img : durgesh,
        name : "Durgesh",
        course : "DCA"
    },
    {
        img : priti,
        name : "Priti",
        course : "O Level"
    },
    
]

export default StudentsData;
