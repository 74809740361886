import React, { useRef, useState } from "react";
import "../../../assets/css/admin.css";
import axios from "axios";
import { toast } from "react-toastify";
import Marksheet from "../../../Components/Marksheet";
import ReactToPrint from "react-to-print";

const GenReportCard = () => {
  const [printBtn, setprintBtn] = useState(false);
  const [reportData, setreportData] = useState();
  const [reportCard, setreportCard] = useState();
  const [markData, setmarkData] = useState({});
  const [mark_Details, setmarkDetails] = useState([]);
  var obtained_Marks = 0,
    total_Marks = 0;
  const addMarkData = (e) => {
    e.preventDefault();
    if (
      !markData.total_Mark ||
      !markData.subject_Name ||
      !markData.obtained_Mark
    ) {
      return toast.warn("Enter marks details");
    }
    setmarkDetails([...mark_Details, markData]);
    setmarkData({
      total_Mark: "",
      subject_Name: "",
      obtained_Mark: "",
    });
  };

  const generateReport = async () => {
    const issued_Date = Date();
    mark_Details.map((i) => {
      obtained_Marks += parseInt(i.obtained_Mark);
      total_Marks += parseInt(i.total_Mark);
    });
    var grade;
    var percentage = obtained_Marks / total_Marks;
    if (percentage >= 0.9) {
      grade = "A+";
    } else if (percentage >= 0.8) {
      grade = "A";
    } else if (percentage >= 0.7) {
      grade = "B";
    } else {
      grade = "c";
    }
    try {
      const {
        name,
        studentID,
        father_Name,
        mother_Name,
        dob,
        course_Title,
        course_Duration,
      } = reportCard;

      if (
        !name ||
        !studentID ||
        !father_Name ||
        !mother_Name ||
        !dob ||
        !course_Title ||
        !course_Duration
      ) {
        return toast.warn("Enter all details");
      }
      const { data } = await axios.post(`/v1/marksheet/add-new-marksheet`, {
        name,
        studentID,
        father_Name,
        mother_Name,
        dob,
        course_Title,
        course_Duration,
        obtained_Marks,
        total_Marks,
        issued_Date,
        grade,
        mark_Details,
      });
      if (data?.success) {
        toast.success(data?.message);
        setprintBtn(true);
        setreportData(data?.details);
        return;
      }
      toast.error(data?.message);
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
      toast.error("Something went wrong!!");
    }
  };

  const componentRef = useRef();
  return (
    <div className="gen-report-card-page">
      <hr />
      <h2>Personal Details</h2>
      <hr />
      <div className="personal-details">
      <input
          type="text"
          placeholder="Student ID"
          onChange={(e) =>
            setreportCard({
              ...reportCard,
              studentID: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="Student Name"
          onChange={(e) =>
            setreportCard({
              ...reportCard,
              name: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="father Name"
          onChange={(e) =>
            setreportCard({
              ...reportCard,
              father_Name: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="mother Name"
          onChange={(e) =>
            setreportCard({
              ...reportCard,
              mother_Name: e.target.value,
            })
          }
        />
        <input
          type="date"
          placeholder="dob"
          onChange={(e) =>
            setreportCard({
              ...reportCard,
              dob: e.target.value,
            })
          }
          title="Choose Date of Birth"
        />

        <input
          type="text"
          placeholder="Course Name"
          onChange={(e) =>
            setreportCard({
              ...reportCard,
              course_Title: e.target.value.toUpperCase(),
            })
          }
        />
        <input
          type="number"
          placeholder="Duration in Months"
          onChange={(e) =>
            setreportCard({
              ...reportCard,
              course_Duration: e.target.value,
            })
          }
        />
      </div>
      <hr />
      <h2>Mark Details</h2>
      <hr />
      <div className="mark-details">
        <table border={"2px solid"}>
          <tr>
            <td>
              <input
                type="text"
                placeholder="Subject Name"
                onChange={(e) =>
                  setmarkData({
                    ...markData,
                    subject_Name: e.target.value.toUpperCase(),
                  })
                }
                value={markData.subject_Name}
              />
            </td>
            <td>
              <input
                type="number"
                placeholder="Total Mark"
                onChange={(e) =>
                  setmarkData({ ...markData, total_Mark: e.target.value })
                }
                value={markData.total_Mark}
              />
            </td>
            <td>
              <input
                type="number"
                placeholder="Obtained Mark"
                onChange={(e) =>
                  setmarkData({ ...markData, obtained_Mark: e.target.value })
                }
                value={markData.obtained_Mark}
              />
            </td>
            <td>
              <button
                className="btn"
                style={{ background: "green", width: "100px", margin: "0" }}
                onClick={addMarkData}
              >
                Add
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div className="mark-details">
        <table border={"2px solid"}>
          <tr>
            <th>SNo.</th>
            <th>Subject Name</th>
            <th>Total Mark</th>
            <th>Obtained Mark</th>
          </tr>
          {mark_Details?.map((item) => (
            <tr>
              <td>{item.index}</td>
              <td>{item?.subject_Name}</td>
              <td>{item?.total_Mark}</td>
              <td>{item?.obtained_Mark}</td>
            </tr>
          ))}
        </table>
      </div>
      <div style={{ paddingTop: "20px", textAlign: "right" }}>
        <button
          className="btn"
          style={{ backgroundColor: "blue", width: "200px" }}
          onClick={generateReport}
        >
          Submit{" "}
          <i
            className="fa-solid fa-arrow-up-right-from-square"
            style={{ marginLeft: "6px" }}
          />{" "}
        </button>
      </div>

      <div style={{ display: `${printBtn ? "block" : "none"}` }}>
        <div>
          <ReactToPrint
            trigger={() => (
              <button
                className="btn"
                style={{ width: "250px", background: "green" }}
              >
                Print Marksheet{" "}
                <i
                  className="fa-solid fa-print"
                  style={{ marginLeft: "6px" }}
                />
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <Marksheet ref={componentRef} data={reportData} />
      </div>
    </div>
  );
};

export default GenReportCard;
