import React from "react";
import { NavLink } from "react-router-dom";
import "../../assets/css/dashboard.css";
import { useAuth } from "../../ContextApi/authContext";

const adminMenu = [
  {
    id: 1,
    title: "New Admission",
    path: "v1/admin/new-admission",
    icon: "address-card",
  },
  {
    id: 2,
    title: "Student Details",
    path: "v1/admin/student-details",
    icon: "user-graduate",
  },
  {
    id: 3,
    title: "Courses",
    path: "v1/admin/course-details",
    icon: "file",
  },
  {
    id: 4,
    title: "Live Class",
    path: "v1/admin/live-classes",
    icon: "video",
  },
  {
    id: 5,
    title: "Report Card",
    path: "v1/admin/students-report-card",
    icon: "graduation-cap",
  },
  {
    id: 6,
    title: "Certificate",
    path: "v1/admin/students-certificate",
    icon: "certificate",
  },
  {
    id: 7,
    title: "Teachers",
    path: "v1/admin/teacher-details",
    icon: "users",
  },
  {
    id: 8,
    title: "Profile",
    path: "v1/admin/profile",
    icon: "gear",
  },
  {
    id: 9,
    title: "Password Change",
    path: "v1/admin/password-change",
    icon: "key",
  },
];

const teacherMenu = [
  {
    id: 1,
    title: "Course Details",
    path: "course-details",
    icon: "file",
  },
  {
    id: 5,
    title: "Live Class",
    path: "live-classes",
    icon: "video",
  },
  {
    id: 8,
    title: "Profile",
    path: "profile",
    icon: "gear",
  },
  {
    id: 9,
    title: "Password Change",
    path: "password-change",
    icon: "key",
  },
];
const studentMenu = [
  {
    id: 1,
    title: "Enrolled Courses",
    path: "enrolled-courses",
    icon: "file",
  },
  {
    id: 2,
    title: "Id Card",
    path: "student-id-card",
    icon: "address-card",
  },
  {
    id: 3,
    title: "Certificate",
    path: "student-certificate",
    icon: "certificate",
  },
  {
    id: 4,
    title: "Report Card",
    path: "student-report-card",
    icon: "graduation-cap",
  },
  {
    id: 5,
    title: "Live Class",
    path: "live-classes",
    icon: "video",
  },
  {
    id: 8,
    title: "Profile",
    path: "profile",
    icon: "gear",
  },
  {
    id: 9,
    title: "Password Change",
    path: "password-change",
    icon: "key",
  },
];
const DashboardMenu = () => {
  const [auth] = useAuth();
  const role = auth?.user?.role;

  if (role === "admin") {
    var menu = adminMenu;
  } else if (role === "teacher") {
    menu = teacherMenu;
  } else {
    menu = studentMenu;
  }

  return (
    <>
      <div className="dashboard-menu-page">
        <>
          <h2 style={{ textTransform: "uppercase" }}>{role} Panel</h2>
          {menu.map((item) => (
            <div key={item.id}>
              <NavLink to={item.path}>
                <i
                  className={`fa-solid fa-${item.icon}`}
                  style={{ marginRight: "10px", fontSize: "22px" }}
                />
                {item.title}
              </NavLink>
            </div>
          ))}
        </>
      </div>
    </>
  );
};

export default DashboardMenu;
