import React, { useState } from "react";
import Layout from "../layouts/Layout";
import "../assets/css/result.css";
import Sppinner from "../Animations/Sppinner";
import { Helmet } from "react-helmet";

const Result = () => {
  const [animation, setAniamtion] = useState(false);
  const [StudentID, setStudentID] = useState();
  const [dob, setdob] = useState(false);

  const getResult = () => {
    try {
      setAniamtion(true);
    } catch (error) {}
  };
  return (
    <Layout>
      <Helmet >
        <title>Exam Results</title>
        <meta name="keywords" content="Rajediha, computer center, training, computer course, results, exam-results" />
      </Helmet>
      <div className="result_page">
        <div className="find_result">
          <div>
            <input
              type="text"
              placeholder="Enter Student ID"
              onChange={(e) => setStudentID(e.target.value)}
              value={StudentID}
            />
          </div>
          <label htmlFor="">Date Of Birth</label>
          <div>
            <input
              type="date"
              onChange={(e) => setdob(e.target.value)}
              value={dob}
            />
          </div>
          <div style={{ paddingTop: "20px" }}>
            <button
              className="btn"
              onClick={getResult}
              style={{ backgroundColor: "blue", margin: "0", width: "100%" }}
            >
              Submit{" "}
              <i
                className="fa-solid fa-arrow-up-right-from-square"
                style={{ marginLeft: "6px" }}
              />{" "}
            </button>
          </div>
        </div>

        {animation ? <Sppinner /> : "Result Found"}
      </div>
    </Layout>
  );
};

export default Result;
