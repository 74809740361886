import React from "react";
import "../assets/css/footer.css";

export default function Footer() {
  return (
    <>
      <div
        className="footer"
        style={{
          textAlign: "center",
          marginTop: "2px",
          paddingBottom: "5px",
        }}
      >
        <div
          className="footer_top"
          style={{ color: "white", paddingBottom: "10px" }}
        >
          Center Address - Rajediha Bazar, Santha, Sant Kabit Nagar, Uttar
          Pradesh
        </div>
        <div className="footer_top" style={{ color: "white" }}>
          &copy; copyright 2024, All Right are reserve <br />
          Akriti Computer Center
        </div>
        <div
          style={{ color: "white", marginTop: "20px", paddingBottom: "20px" }}
        >
          <a
            href="https://www.facebook.com/ramakumar"
            target="_blank"
            style={{ fontSize: "18px", color: "white", margin: "5px 10px" }}
          >
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/ramakumar3450"
            target="_blank"
            style={{ fontSize: "18px", color: "white", margin: "5px 10px" }}
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://www.youtube.com/@RamaKumar-lb4sf"
            target="_blank"
            style={{ fontSize: "18px", color: "white", margin: "5px 10px" }}
          >
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a
            href="tel:9721107634"
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "18px", color: "white", margin: "5px 10px" }}
          >
            <i className="fa-solid fa-phone"></i>
          </a>
          <a
            href="mailto:kumarrama9774@gmail.com"
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "18px", color: "white", margin: "5px 10px" }}
          >
            <i className="fa-solid fa-envelope"></i>
          </a>
          <a
            href="https://maps.app.goo.gl/Q8oWUubnBdghwNjDA"
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "18px", color: "white", margin: "5px 10px" }}
          >
            <i className="fa-solid fa-location-dot"></i>
          </a>
        </div>
      </div>
    </>
  );
}
