import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const EnrollStudent = ({ courseData, popUp, callback }) => {
  const [allStudent, setAllStudent] = useState(courseData?.enrolled_Students);
  const [newStudent, setNewStudent] = useState([]);
  const [studentID, setStudentID] = useState();

  const getStudentData = async (e) => {
    e.preventDefault();
    // allStudent?.map((i) => {
    //   if (i?.studentID === studentID) {
    //     console.log(i)
    //     toast.success("Student Already Enrolled");
    //     return;
    //   }
    // });
    try {
      const { data } = await axios.get(
        `/v1/auth/student/get-profile-details/${studentID}`
      );
      if (data?.success) {
        setAllStudent([...allStudent, data?.details]);
        setNewStudent([...newStudent, data?.details]);
        setStudentID("");
        // toast.success("Added successfully!");
        return;
      }
      toast.error("Enter Correct ID");
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
    }
  };

  const enroll = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `/v1/course//update-course/${courseData?._id}`,
        { enrolled_Students: allStudent }
      );
      if (data?.success) {
        newStudent?.map(async (item) => {
          await axios.put(`/v1/auth/student/profile-update/${item?._id}`, {
            enrolled_Courses: [
              ...item?.enrolled_Courses,
              { ...courseData, enrolled_Date: Date() },
            ],
          });
        });
        toast.success("Student Enrolled Successfully");
        popUp(false);
        setNewStudent([]);
        callback();
        return;
      }
      toast.error("Can't Enrolled");
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
    }
  };
  return (
    <div className="enroll-student-comp">
      <form>
        <div>
          <input
            type="text"
            placeholder="Enter Student ID"
            style={{ width: "300px" }}
            onChange={(e) => {
              setStudentID(e.target.value);
            }}
            value={studentID}
          />
          <button
            className="btn"
            style={{ background: "green", width: "100px" }}
            onClick={getStudentData}
          >
            Add
          </button>
        </div>
        <div>
          <table border="2px solid red">
            <tr>
              <th>Student ID</th>
              <th>Name</th>
              <th>Mobile No</th>
            </tr>
            {newStudent?.map((item) => (
              <tr>
                <td>{item?.studentID}</td>
                <td>{item?.name}</td>
                <td>{item?.mobileNo}</td>
              </tr>
            ))}
          </table>
        </div>
        <div
          className="action-btn"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "50px",
          }}
        >
          <button
            className="btn"
            style={{ background: "red", width: "200px" }}
            onClick={(e) => {
              e.preventDefault();
              popUp(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn"
            style={{ background: "blue", width: "250px" }}
            onClick={enroll}
          >
            Enroll
            <i
              className="fa-solid  fa-arrow-up-right-from-square"
              style={{ marginLeft: "6px" }}
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default EnrollStudent;
