import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts/Navbar";
import DashboardMenu from "../DashboardMenu";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../ContextApi/authContext";
import ApiCall from "../../../customHooks/ApiCall";
import Loader from "../../../Animations/Loader";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const StudentDashboard = () => {
  const [status, setStatus] = useState(false);
  const [auth] = useAuth();
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const authCheck = async () => {
      const data = await ApiCall("get", "/v1/private-route/student-route");
      setMsg(data?.message);

      if (data?.success) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    };
     authCheck();
  }, [auth]);
  return (
    <>
    <Helmet>
        <title>Dashboard - Student</title>
        <meta
          name="keywords"
          content="Rajediha, computer center, training, computer course, dashboard "
        />
      </Helmet>
      <Navbar />
      {!status ? (
        <Loader msg={msg} />
      ) : (
        <div className="dashboard-page">
          <div className="dashboard-page-left">
            <DashboardMenu />
          </div>
          <div className="dashboard-page-right">
            <Outlet />
          </div>
        </div>
      )}
      <ToastContainer position="top-center" theme="colored" />
    </>
  );
};

export default StudentDashboard;
