import React from "react";
import "./about.css";
import img from "../../assets/img/ramaimg.jpeg";
import Card from "./Card";
import Message from "./message/Message";
import Layout from "../../layouts/Layout";
import {Helmet} from "react-helmet"
export default function AboutUs() {
  return (
    <Layout>
      <Helmet >
        <title>About Us</title>
        <meta name="keywords" content="Rajediha, computer center, training, computer course, about us, rama kumar" />
      </Helmet>
      <div className="about_us">
        <Message />

        <div>
          <p className="about_us_heading" data-aos="fade-up">director</p>
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            className="our-team"
            data-aos="fade-up"
          >
            <Card
              img={img}
              name="Rama Kumar"
              degree="BSc (CSE)"
              email="kumarrama9774@gmail.com"
              number="9721107634"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
