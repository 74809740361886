import axios from "axios";
import { toast } from "react-toastify";

const base_url = process.env.REACT_APP_API;

const ApiCall = async (method, url, sendData) => {
  try {
    const { data } = await axios({
      method,
      url,
      baseURL: base_url,
      data: sendData,
    });
    return data;
  } catch (error) {
    console.log("Error in APi call: " + error);
    toast.error("Something went wrong");
  }
};

export default ApiCall;
