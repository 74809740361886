import React, { useEffect, useState } from "react";
import "../../../assets/css/admin.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import EnrollStudent from "../../../Components/EnrollStudent";
import UpdateCourse from "../../../Components/UpdateCourse";
import DeletePopUP from "../../../Components/DeletePopUP";

const SingleCourseDetails = () => {
  const [CourseData, setCourseData] = useState();
  const [enrollPopUp, setEnrollPopUP] = useState(false);
  const [updatePopUp, setUpdatePopUP] = useState(false);
  const [deletePopUp, setDeletePopUP] = useState(false);

  const base_URL = process.env.REACT_APP_API;
  const { id } = useParams();

  const getCourseData = async () => {
    try {
      const { data } = await axios.get(
        `/v1/course/single-course-details/${id}`
      );
      if (data?.success) {
        setCourseData(data?.details);
      }
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
    }
  };
  useEffect(() => {
    getCourseData();
  }, []);
  return (
    <>
      <div className="single-course-details-page">
        <div className="course-img">
          <img
            src={`${base_URL}/${CourseData?.course_Image}`}
            alt="course"
          />
        </div>
        <div className="course-details">
          <h1>Course Title : {CourseData?.course_Title}</h1>
          <h2>
            Course Category : <span>{CourseData?.course_Category}</span>
          </h2>
          <h2>
            Course Duration : <span>{CourseData?.course_Duration} months</span>{" "}
          </h2>
          <h2>
            Course Fees :
            <span>
              {" "}
              <i
                className="fa-solid fa-indian-rupee-sign"
                style={{ marginRight: "4px" }}
              />
              {CourseData?.course_Fee}
            </span>
          </h2>
        </div>

        <div className="enrolled-students">
          <h2>Enrolled Students Details</h2>
          <table border={"1px solid gray"}>
            <tr>
              <th>Student Id</th>
              <th>Student Name</th>
              <th>Mobile No.</th>
              <th style={{ width: "100px" }}>Action</th>
            </tr>
            {CourseData?.enrolled_Students?.map((item) => (
              <tr key={item?.studentID}>
                <td>
                  <Link
                    to={`/dashboard/v1/admin/student/profile-details/${item?.studentID}`}
                    style={{ textDecoration: "none" }}
                  >
                    {item?.studentID}
                  </Link>
                </td>
                <td> {item?.name}</td>
                <td> {item?.mobileNo}</td>
                <td style={{ width: "100px" }}>
                  <button
                    className="btn"
                    style={{
                      padding: "0",
                      width: "auto",
                      margin: "0",
                      background: "transparent",
                    }}
                    title="Delete Student"
                  >
                    <i
                      className="fa-solid fa-trash-can"
                      style={{ color: "red", fontSize: "20px" }}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>

        <div className="action-btn">
          <button
            className="btn"
            style={{ background: "red", width: "250px" }}
            onClick={() => setDeletePopUP(true)}
          >
            <i
              className="fa-solid fa-trash-can"
              style={{ marginRight: "6px" }}
            />
            Delete Course
          </button>
          <button
            className="btn"
            style={{ background: "green", width: "250px" }}
            onClick={() => setUpdatePopUP(true)}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ marginRight: "6px" }}
            />
            Update Course
          </button>
          <button
            className="btn"
            style={{ background: "blue", width: "250px" }}
            onClick={() => setEnrollPopUP(true)}
          >
            Enroll Student{" "}
            <i
              className="fa-solid  fa-arrow-up-right-from-square"
              style={{ marginLeft: "6px" }}
            />
          </button>
        </div>
      </div>
      {/* POPUP SECTION */}
      <div
        className="popup"
        style={{ display: `${enrollPopUp ? "block" : "none"}` }}
      >
        <EnrollStudent popUp={setEnrollPopUP} courseData={CourseData} />
      </div>
      <div
        className="popup"
        style={{ display: `${updatePopUp ? "block" : "none"}` }}
      >
        <UpdateCourse popUp={setUpdatePopUP} courseData={CourseData} setCourseData={setCourseData} />
      </div>
      <div
        className="popup"
        style={{ display: `${deletePopUp ? "block" : "none"}` }}
      >
        <DeletePopUP courseId={CourseData?._id} popUp={setDeletePopUP} callback={getCourseData}/>
      </div>
    </>
  );
};

export default SingleCourseDetails;
