import React, { useRef, useState } from "react";
import Layout from "../layouts/Layout";
import "../assets/css/verification.css";
import "../assets/css/components.css";
import Sppinner from "../Animations/Sppinner";
import { toast } from "react-toastify";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Marksheet from "../Components/Marksheet";
import { Helmet } from "react-helmet";

const MarksheetVerification = () => {
  const [animation, setAnimation] = useState(false);
  const [marksheetFound, setmarksheetFound] = useState(false);
  const [marksheetNo, setmarksheetNo] = useState("");
  const [marksheetData, setmarksheetData] = useState();
  const componentRef = useRef();

  const getMarksheet = async (e) => {
    e.preventDefault();
    try {
      if (!marksheetNo) {
        return toast.warn("Enter Marksheet Number");
      }
      setmarksheetFound(false);
      setAnimation(true);
      const { data } = await axios.get(
        `/v1/marksheet/single-marksheet-details/${marksheetNo}`
      );
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        setmarksheetData(data?.details);
        setmarksheetFound(true);
        return;
      }
      toast.error(data?.message);
    } catch (error) {
      setAnimation(false);
      console.log("Found error in marksheet verification: " + error);
      toast.error("Something wen wrong!!");
    }
  };
  return (
    <Layout>
      <Helmet >
        <title>Marksheet Verification</title>
        <meta name="keywords" content="Rajediha, computer center, training, computer course, result verification, verification, marksheet  verification " />
      </Helmet>
      <div className="verification_page ">
        <div className="search-student-id" style={{ paddingTop: "10px" }}>
          <input
            type="text"
            placeholder="Enter Marksheet SL No"
            onChange={(e) => {
              setmarksheetNo(e.target.value.toUpperCase());
            }}
            value={marksheetNo}
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              width: "300px",
            }}
          />
          <button
            className="btn"
            style={{ background: "green", width: "150px" }}
            onClick={getMarksheet}
          >
            Search
          </button>
        </div>
        <div style={{ display: `${animation ? "block" : "none"}` }}>
          <Sppinner />
        </div>

        <div style={{ display: `${marksheetFound ? "block" : "none"}` }}>
          <div className="result_found">
            {/* <div className="student-personal-details">
              <div>
                <div>SL No.</div>
                <div>Name </div>
                <div>father Name </div>
                <div>mother Name </div>
                <div>DOb </div>
              </div>
              <div style={{ padding: "0 30px" }}>
                <div>:</div>
                <div>:</div>
                <div>:</div>
                <div>:</div>
                <div>:</div>
              </div>

              <div>
                <div>{marksheetData?.marksheet_No}</div>
                <div>{marksheetData?.name}</div>
                <div>{marksheetData?.father_Name}</div>
                <div>{marksheetData?.mother_Name}</div>
                <div>{marksheetData?.dob}</div>
              </div>
            </div>{" "} */}
            <div className="mark-details">
              <h2>Marks Details</h2>
              <table border={"2px solid"} style={{ width: "100%" }}>
                <tr>
                  <th>SNo.</th>
                  <th>Subject Name</th>
                  <th>Total Mark</th>
                  <th>Obtained Mark</th>
                </tr>
                {marksheetData?.mark_Details.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.subject_Name}</td>
                    <td>{item?.total_Mark}</td>
                    <td>{item?.obtained_Mark}</td>
                  </tr>
                ))}
              </table>
              <div className="course-details">
                <div>
                  <div>Course </div>
                  <div>Course Duration </div>
                  <div>Total Marks </div>
                  <div>Obtained Marks </div>
                  <div>Percentage </div>
                  <div>Grade</div>
                </div>
                <div style={{ padding: "0 30px" }}>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                </div>
                <div>
                  <div> {marksheetData?.course_Title}</div>
                  <div>{marksheetData?.course_Duration}</div>
                  <div> {marksheetData?.total_Marks}</div>
                  <div>{marksheetData?.obtained_Marks}</div>
                  <div>
                    {" "}
                    {((marksheetData?.obtained_Marks / marksheetData?.total_Marks) * 100).toFixed(
                      2
                    )}
                    %
                  </div>
                  <div>{marksheetData?.grade}</div>
                </div>
              </div>
            </div>
            <div
              style={{
                color: "darkred",
                fontWeight: "600",
                paddingTop: "20px",
              }}
            >
              Issue Date : {marksheetData?.issued_Date.split("T")[0]}
            </div>
          </div>

          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
            }}
          >
            <h3 style={{ fontWeight: "600" }}>
              Marksheet Successfully Verified
            </h3>
            <div>
              <ReactToPrint
                trigger={() => (
                  <button
                    className="btn"
                    style={{ width: "250px", background: "blue" }}
                  >
                    Print Marksheet
                    <i
                      className="fa-solid fa-print"
                      style={{ marginLeft: "6px" }}
                    />
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>

        <div style={{ display: "none" }}>
          <Marksheet ref={componentRef} data={marksheetData} />
        </div>
      </div>
    </Layout>
  );
};

export default MarksheetVerification;
