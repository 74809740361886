import React, { useState } from "react";
import AddNewTeacher from "../../../Components/AddNewTeacher";
import { Link } from "react-router-dom";

const Teachers = () => {
  const [newTeacher, setNewTeacher] = useState(false);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="teachers-page"
          style={{ opacity: `${newTeacher ? "0.02" : "1"}` }}
        >
          <div className="add-new-teacher">
            <div style={{ textAlign: "right" }}>
              <button
                className="btn"
                style={{ background: "blue", width: "300px", height: "60px" }}
                onClick={() => {
                  setNewTeacher(true);
                }}
              >
                New Teacher
                <i
                  className="fa-solid fa-arrow-up-right-from-square"
                  style={{ marginLeft: "8px" }}
                />
              </button>
            </div>
          </div>

          <div className="teacher-details">
            <table border={"2px solid gray"}>
              <tr>
                <th>Teacher Id</th>
                <th>Teacher Name</th>
                <th>Mobile No.</th>
                <th>Email Id</th>
                <th>Qualification</th>
                <th>Action</th>
              </tr>
              <tr>
                <td>
                  <Link
                    to={
                      "/dashboard/v1/admin/teacher/profile-details/AS12333443322"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    AS12333443322
                  </Link>
                </td>
                <td>Satish Kumar Maurya</td>
                <td>+91 7985017186</td>
                <td>satishmaurya22@gmail.com</td>
                <td style={{ textTransform: "" }}>B.Tech</td>
                <td>
                  <button
                    className="btn"
                    style={{
                      padding: "0",
                      width: "auto",
                      marginRight: "10px",
                      background: "transparent",
                    }}
                    title="Delete Teacher"
                  >
                    <i
                      className="fa-solid fa-trash-can"
                      style={{ color: "red", fontSize: "20px" }}
                    />
                  </button>
                  <button
                    className="btn"
                    style={{
                      padding: "0",
                      width: "auto",
                      margin: "0",
                      background: "transparent",
                    }}
                    title="Make Admin"
                  >
                    <i
                      className="fa-solid fa-user-gear"
                      style={{ color: "green", fontSize: "22px" }}
                    />
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style={{ display: `${newTeacher ? "block" : "none"}` }}>
          <AddNewTeacher cancel={setNewTeacher} />
        </div>
      </div>
    </>
  );
};

export default Teachers;
