import React from "react";

export default function Card(props) {
  return (
    <>
      <div className="faculty faculty1">
        <img src={props.img} alt="" className="faculty_img" />
        <h2 className="faculty_name">{props.name}</h2>
        <h4 className="faculty_qualification">{props.degree}</h4>
        <div className="contact_faculty">
          <a href={`mailto:${props.email}`}>
            <i className="fas fa-envelope" style={{ marginLeft: "2px" }}></i>
          </a>
          <a href={`tel:${props.number}`}>
            <i className="fas fa-phone" style={{ marginLeft: "3px" }}></i>
          </a>
          <a href={`tel:${props.number}`}>
            <i className="fas fa-whatsapp" style={{ marginLeft: "3px" }}></i>
          </a>
        </div>
      </div>
    </>
  );
}
