import React, { useState } from "react";
import "../assets/css/components.css";
import { toast } from "react-toastify";
import axios from "axios";
import Sppinner from "../Animations/Sppinner";

const UpdateCourse = ({ popUp, courseData, setCourseData }) => {
  const [animation, setAnimation] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCourseData({ ...courseData, [name]: value });
  };

  const sendData = async (e) => {
    e.preventDefault();
    const {
      course_Title,
      course_Fee,
      course_Duration,
      course_Category,
      image,
    } = courseData;

    if (!course_Title || !course_Fee || !course_Duration || !course_Category) {
      return toast.warn("Enter all details");
    }
    const formData = new FormData();
    formData.append("course_Category", course_Category);
    formData.append("course_Title", course_Title);
    formData.append("course_Duration", course_Duration);
    formData.append("course_Fee", course_Fee);
    formData.append("course_Image", image);
    try {
      setAnimation(true);
      const { data } = await axios.put(
        `/v1/course//update-course/${courseData?._id}`,
        formData
      );
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        popUp(false);
        return;
      }
      toast.error(data?.message);
    } catch (error) {
      setAnimation(false);
      toast.error("Something went wrong!!")
    }
  };

  return (
    <>
      <div className="add-new-course-comp">
        <div style={{ display: `${animation ? "block" : "none"}` }}>
          <Sppinner />
        </div>
        <form style={{ display: `${!animation ? "block" : "none"}` }}>
          <h2>UPDATE COURSE</h2>
          <div>
            <select
              name="course_Category"
              onChange={handleChange}
              value={courseData?.course_Category}
            >
              <option value="">--- Choose Course Category ---</option>
              <option value="Web-Development">Web Development</option>
              <option value="Software-Development">Software Engineering</option>
              <option value="Programming">Programming Courses</option>
              <option value="Microsoft-Courses">Microsoft Office</option>
              <option value="Diploma-Courses">Diploma Courses</option>
              <option value="Bachelor-Courses">Bachelor Courses</option>
              <option value="TALLY">Tally</option>
              <option value="Basic-Computer-Courses">
                Basic Computer Courses
              </option>
              <option value="Digital-Marketing">Digital Marketing</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              placeholder="Course Title"
              name="course_Title"
              value={courseData?.course_Title}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="Number"
              placeholder="Course Duration in Months"
              name="course_Duration"
              value={courseData?.course_Duration}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="Number"
              placeholder="Course Fee"
              name="course_Fee"
              value={courseData?.course_Fee}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="c-img">Upload Course Image</label>
            <input
              type="file"
              id="c-img"
              onChange={(e) =>
                setCourseData({ ...courseData, image: e.target.files[0] })
              }
            />
          </div>
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              className="btn"
              style={{ background: "red" }}
              onClick={(e) => {
                e.preventDefault();
                popUp(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn"
              style={{ background: "blue" }}
              onClick={sendData}
            >
              <span>Update</span>
              <i
                className="fa-solid fa-arrow-up-right-from-square"
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateCourse;
