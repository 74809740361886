import React, {useState } from "react";
import "../../../assets/css/admin.css";
import { toast } from "react-toastify";
import Sppinner from "../../../Animations/Sppinner";
import StudentIdCard from "../../../Components/StudentIdCard";
import axios from "axios";
import { usePDF } from "react-to-pdf";

const NewAdmission = () => {
  const [studentData, setStudentData] = useState({});
  const [image, setImage] = useState();
  const [animation, setAnimation] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [regStudent, setRegStudent] = useState();
  const { toPDF, targetRef } = usePDF({
    filename: `student-id-${regStudent?.details?.studentID}.pdf`,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setStudentData({ ...studentData, [name]: value });
  };

  const sendData = async (e) => {
    e.preventDefault();
    const {
      name,
      father_Name,
      mother_Name,
      mobileNo,
      email,
      dob,
      gender,
      enrolled_Course,
      address,
    } = studentData;

    if (
      !name ||
      !father_Name ||
      !mother_Name ||
      !mobileNo ||
      !email ||
      !dob ||
      !gender ||
      !address
    ) {
      return toast.warn("Enter all details");
    }
    if (!enrolled_Course) {
      return toast.warn("Select course");
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("father_Name", father_Name);
    formData.append("mother_Name", mother_Name);
    formData.append("gender", gender);
    formData.append("mobileNo", mobileNo);
    formData.append("email", email);
    formData.append("dob", dob);
    formData.append("address", address);
    formData.append("image", image);
    try {
      setAnimation(true);
      const { data } = await axios.post("/v1/auth/student/register", formData);
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        setRegStudent(data);
        setPopUp(true);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      setAnimation(false);
    }
  };

  return (
    <>
      <div className="new-admission-page" style={{ position: "relative" }}>
        {animation ? (
          <Sppinner />
        ) : (
          <form>
            <h2>New Admission Form</h2>
            <div>
              <input
                type="text"
                placeholder="Student Name"
                name="name"
                value={studentData.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Father Name"
                name="father_Name"
                value={studentData.father_Name}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Mother Name"
                name="mother_Name"
                value={studentData.mother_Name}
                onChange={handleChange}
              />
            </div>
            <div style={{ display: "inline" }}>
              <label htmlFor="" style={{ display: "inline" }}>
                Gender
              </label>
            </div>
            <span>
              <label htmlFor="male" style={{ marginLeft: "50px" }}>
                Male
              </label>
              <input
                type="radio"
                name="gender"
                id="male"
                value="male"
                onChange={handleChange}
                style={{
                  margin: "20px 5px",
                  padding: "10px",
                  display: "inline",
                }}
              />
            </span>
            <span>
              <label htmlFor="female" style={{ marginLeft: "50px" }}>
                Female
              </label>
              <input
                type="radio"
                name="gender"
                value="female"
                onChange={handleChange}
                id="female"
                style={{
                  margin: "10px 5px",
                  padding: "20px",
                  display: "inline",
                }}
              />
            </span>

            <div>
              <label htmlFor="">Date of Birth</label>
              <input
                type="date"
                name="dob"
                value={studentData.dob}
                max={"2024-04-01"}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="number"
                placeholder="Mobile Number"
                name="mobileNo"
                value={studentData.mobileNo}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email ID"
                name="email"
                value={studentData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Address"
                name="address"
                value={studentData.address}
                onChange={handleChange}
              />
            </div>
            <div>
              <select
                name="enrolled_Course"
                value={studentData.enrolled_Course}
                onChange={handleChange}
              >
                <option value="">--- Select Course ---</option>
                <option value="adca">ADCA</option>
                <option value="cpp">CPP</option>
                <option value="node">NODE</option>
                <option value="java">JAVA</option>
              </select>
            </div>
            <div>
              <label htmlFor="photo">Upload Student Photo</label>
              <input
                type="file"
                placeholder="Select Course Image"
                id="photo"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
              />
            </div>
            <div style={{ paddingTop: "20px", textAlign: "right" }}>
              <button
                className="btn"
                onClick={sendData}
                style={{ backgroundColor: "blue", width: "200px" }}
              >
                Submit{" "}
                <i
                  className="fa-solid fa-arrow-up-right-from-square"
                  style={{ marginLeft: "6px" }}
                />{" "}
              </button>
            </div>
          </form>
        )}
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            display: `${popUp ? "block" : "none"}`,
            width: "100%",
            background: "lightgray",
            opacity: "0.99",
            paddingTop: "30px",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "660px",
              borderRadius: "5px",
              padding: "30px 20px",
              background: "white",
              margin: "0 auto",
            }}
          >
            <h1 style={{ textAlign: "center", fontWeight: "600" }}>
              SUCCESSFULLY REGISTERED
            </h1>
            <hr />
            <h3 style={{ padding: "10px 30px", fontWeight: "550" }}>
              <div>Student Id : {regStudent?.details?.studentID}</div>
              <div>Password : {regStudent?.sysPassword}</div>
            </h3>
            <hr />
            <br />
            <div ref={targetRef}>
              <StudentIdCard data={regStudent?.details} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "30px",
              }}
            >
              <button
                className="btn"
                onClick={() => setPopUp(false)}
                style={{
                  background: "lightgray",
                  color: "blue",
                  width: "150px",
                }}
              >
                OK
              </button>
              <button
                className="btn"
                style={{ width: "250px", background: "blue" }}
                onClick={() => toPDF()}
              >
                Print Id Card{" "}
                <i
                  className="fa-solid fa-print"
                  style={{ marginLeft: "6px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAdmission;
