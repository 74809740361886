import React, { useState } from "react";
import SearchStudent from "../../../Components/SearchStudent";
import StudentIdCard from "../../../Components/StudentIdCard";
import { toast } from "react-toastify";
import axios from "axios";
import Sppinner from "../../../Animations/Sppinner";
import { usePDF } from "react-to-pdf";

const StudentDetails = () => {
  const [studentId, setStudentId] = useState("");
  const [animation, setAnimation] = useState(false);
  const [studentData, setStudentData] = useState();
  const { toPDF, targetRef } = usePDF({
    filename: `student-id-${studentData?.studentID}.pdf`,
  });

  const getData = async (e) => {
    e.preventDefault();
    try {
      if (!studentId) {
        return toast.warn("Enter student Id");
      }
      setAnimation(true);
      setStudentData("")
      const { data } = await axios.get(
        `/v1/auth/student/get-profile-details/${studentId}`
      );
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        setStudentData(data?.details);
        return;
      }
      toast.error(data?.message);
    } catch (error) {
      setAnimation(false);
      console.log("Found error in student details: " + error);
      toast.error("Something wen wrong!!");
    }
  };
  return (
    <>
      <div className="student-details-page">
          <div className="search-student-id">
            <input
              type="text"
              placeholder="Enter Student ID"
              onChange={(e) => {
                setStudentId(e.target.value);
              }}
            />
            <button
              className="btn"
              style={{ background: "green", width: "150px" }}
              onClick={getData}
            >
              Search
            </button>
          </div>
          <div style={{display:`${animation ? "block" : "none"}`}}>
            <Sppinner />
          </div>

        <div
          className="searched-student-details"
          style={{ display: `${studentData? "block" : "none"}` }}
        >
          <div>
            <SearchStudent data={studentData} />
          </div>
          <div style={{ display: "block", padding: "20px 0"}} ref={targetRef}>
            <StudentIdCard data={studentData} />
          </div>
          <div
            className="action-btn"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "50px",
            }}
          >
            <button
              className="btn"
              style={{ background: "red", width: "250px" }}
            >
              <i
                className="fa-solid fa-trash-can"
                style={{ marginRight: "6px" }}
              />{" "}
              Delete Account
            </button>
            <button
              className="btn"
              style={{ width: "250px", background: "blue" }}
              onClick={() => toPDF()}
            >
              Print Id Card{" "}
              <i className="fa-solid fa-print" style={{ marginLeft: "6px" }} />
            </button>
            <button
              className="btn"
              style={{ background: "green", width: "270px" }}
              onClick={()=>toast.success("Coming soon...")}
            >
              <i
                className="fa-solid fa-pen-to-square"
                style={{ marginRight: "6px" }}
              />{" "}
              Update Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
