import React from "react";

export default function Message() {
  return (
    <div
      style={{
        padding: "30px 10vw",
        fontSize: "18px",
        color: "white",
        textTransform: "uppercase",
      }}
    >
      <h1 data-aos="fade-up">Who Are We</h1>
      <p data-aos="fade-up">
        "अकृति कंप्यूटर सेंटर, एक स्थान जहां विद्यार्थियों को तकनीकी क्षेत्र में
        उच्चता की ऊँचाइयों की ओर बढ़ने और डिजिटल दुनिया में सफलता प्राप्त करने
        का एक माध्यम प्रदान किया जाता है। हम यहाँ उच्च शिक्षा, नवीन तकनीकी
        ज्ञान, और व्यक्तिगत विकास के लिए समर्पित हैं। हमारा उद्देश्य है एक ऐसे
        स्वाभाविक, सृजनात्मक, और तकनीकी रूप से समृद्धिशील समुदाय की रचना करना,
        जहां प्रत्येक विद्यार्थी अपनी क्षमताओं को विकसित कर सके और नए विचारों को
        अपना सके।
      </p>

      <p data-aos="fade-up">
        हम समझते हैं कि डिजिटल युग में विद्यार्थियों को मार्गदर्शन और सहायता की
        आवश्यकता है, इसलिए हम उन्हें सर्वोत्तम स्तर के शिक्षा, नवीनतम तकनीकी
        ज्ञान, और हाथों का सीधा अनुभव प्रदान करने का प्रयास कर रहे हैं। हम यहाँ
        हैं ताकि हर विद्यार्थी अपनी तकनीकी नौकरी की शुरुआत करने के लिए तैयार हो,
        और सफलता की ओर बढ़ सके।"
      </p>

      <h1 data-aos="fade-up">Our Vision</h1>
      <p data-aos="fade-up">
        हमारा सपना है एक ऐसे समर्थ और उत्कृष्ट समुदाय का निर्माण करने का, जहाँ
        छात्र अपनी तकनीकी क्षमताओं को सुधार सकें और नए विचारों का सामना करने के
        लिए तैयार हों। हम यहाँ एक ऐसे स्थान की स्थापना करने का प्रयास कर रहे हैं
        जहाँ छात्रों को नवीनतम तकनीकी उन्नति के साथ मिले, और वे आत्मनिर्भरता और
        सफलता की ऊँचाइयों को प्राप्त करें।
      </p>
      <p data-aos="fade-up">
        हम उच्च गुणवत्ता वाली शिक्षा, प्रशिक्षण, और सामरिक समर्थन के माध्यम से
        छात्रों को एक सुरक्षित और प्रेरणादायक माहौल प्रदान करने के लिए समर्पित
        हैं। हम यह सुनिश्चित करने का प्रयास करते हैं कि हर छात्र यहाँ से अधिक
        समर्थ, संवेदनशील, और तकनीकी रूप से पूर्ण निकले, तैयार एक सशक्त भविष्य की
        ओर बढ़ने के लिए।"
      </p>
      <h1 data-aos="fade-up">Our Mission</h1>
      <p data-aos="fade-up">
        <b>Akriti Computer Center</b> का मिशन है विद्यार्थियों की तकनीक में
        सफलता के लिए एक प्रेरणा स्थल बनना। हमारा उद्देश्य है:
      </p>

      <p data-aos="fade-up">
        <b>1.शक्तिशाली शिक्षा:</b> उच्च स्तर का और सबसे नए तकनीकी ज्ञान और कौशल
        प्रदान करना, ताकि विद्यार्थी बदलते हुए डिजिटल दुनिया के लिए तैयार हो
        सके।
      </p>

      <p data-aos="fade-up">
        <b>2.नवीनत्व और रचनात्मक सोच:</b> नवाचार और रचनात्मक सोच की संस्कृति को
        बढ़ावा देना, जिससे विद्यार्थियों को तत्कालिक विचार करने, समस्याओं का
        समाधान करने और इंफॉर्मेशन टेक्नोलॉजी के क्षेत्र में नए विचारों का
        अनुसंधान करने की प्रेरणा मिले।
      </p>

      <p data-aos="fade-up">
        <b>3.व्यक्तिगत विकास:</b> प्रतिबद्ध शिक्षण वातावरण, व्यक्तिगत मार्गदर्शन
        और हस्तक्षेप अनुभव के अवसर प्रदान करके प्रत्येक विद्यार्थी का व्यक्तिगत
        और व्यावसायिक विकास को बढ़ाना।
      </p>

      <p data-aos="fade-up">
        <b>4.समुदाय निर्माण:</b> विद्यार्थियों के बीच एक समुदाय भावना को बढ़ाना,
        सहयोग, पीयर लर्निंग और विचारों का आत्मसमर्पण करने के लिए प्रोत्साहित
        करना।
      </p>

      <p data-aos="fade-up">
        <b>5.नैतिक और जिम्मेदार तकनीक उपयोग:</b> तकनीक के उपयोग में नैतिकता और
        जिम्मेदारी के प्रति मजबूत जागरूकता देना, तकनीकी ज्ञान को नैतिकता से लागू
        करने और समाज में सकारात्मक योगदान की महत्वपूर्णता को बढ़ावा देना।
      </p>
    </div>
  );
}
