import React, { useState } from "react";
import UserProfile from "../../../Components/UserProfile";
import { useAuth } from "../../../ContextApi/authContext";
import UpdateProfile from "../../../Components/UpdateProfile";

const Profile = () => {
  const [auth] = useAuth();
  const [popUp, setPopUp] = useState(false);
  return (
    <>
      <div>
        <UserProfile user={auth?.user} />
      </div>
      <div
        className="action-btn"
        style={{
          textAlign: "right",
          padding: "20px 40px",
        }}
      >
        <button
          className="btn"
          style={{ background: "green", width: "250px" }}
          onClick={() => setPopUp(true)}
        >
          <i
            className="fa-solid fa-pen-to-square"
            style={{ marginRight: "6px" }}
          />{" "}
          Update Profile
        </button>
      </div>

      {/* UPDATE POPUP */}
      <div className="popup" style={{ display: `${popUp ? "block" : "none"}` }}>
        <UpdateProfile popUp={setPopUp} />
      </div>
    </>
  );
};

export default Profile;
