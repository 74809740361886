import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StudentsData from "./StudentsData";
import "./studentsrecord.css";

export default function StudentRecord() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="student-record-section slider-container">
        <Slider {...settings}>
          {StudentsData.map((d) => (
            <div className="students-card">
              <div className="students-card-img">
                <img src={d.img} alt={d.name} />
              </div>
              <div className="students-card-text">
                <p>
                  <span style={{ color: "brown" }}></span>
                  {d.name}
                </p>
                <p>
                  {" "}
                  <span style={{ color: "brown" }}>Course - </span>
                  {d.course}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
