import React from "react";
import StudentRecord from "./students/StudentRecord";
import "../../assets/css/home.css";
import bg from "../../assets/img/bg.jpg";
import HomeCarousel from "./carousel/Carousel";
import Layout from "../../layouts/Layout";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  return (
    <Layout>
       <Helmet >
        <title>Welcome to Akriti Computer Center</title>
        <meta name="keywords" content="Rajediha, computer center, training, computer course, rajediha , sant kabir nagar, gallary, students" />
      </Helmet>
      <div className="home-page">
        <div>
          <div>
            <img src={bg} alt="" className="home-bg-img" />
          </div>
          <div className="home-content">
            <h1 data-aos="fade-up"> Welcome to AKRITI COMPUTER CENTER</h1>
            <p data-aos="fade-up">
              At Akriti Computer Center, we're committed to equipping you with
              the vital skills required in today's fast-paced digital landscape.
              Whether you're aiming to advance your career, embark on a new
              professional journey, or simply enhance your tech proficiency, we
              have a wide array of courses designed just for you.
            </p>
            <h1 data-aos="fade-up">Our Course Offerings Include</h1>
            <p data-aos="fade-up">
              {" "}
              <span>Microsoft Courses : </span> Unlock the power of productivity
              with these essential tools like Ms-Excel, Ms-word, PowerPoint and
              many more.
            </p>{" "}
            <p data-aos="fade-up">
              <span>Web Development : </span> Learn today's powerfull courses
              like HTML, CSS, JavaScript, Bootstrap, mongoDB and cutting-edge
              frameworks like Node.js and React.
            </p>
            <p data-aos="fade-up">
              <span>Professional Certifications : </span> Pursue recognized
              qualifications such as BCC, DCA, BCA, O Level, and A Level.
            </p>
            <p style={{ textAlign: "right" }} data-aos="fade-right">
              <button
                className="btn"
                style={{ background: "blue", width: "200px" }}
                onClick={() => navigate("/courses")}
              >
                Explore more
              </button>
            </p>
            <h1 data-aos="fade-up">Why Choose Us? </h1>
            <p data-aos="fade-up">
              <span>Experienced Instructors : </span> Our seasoned educators
              provide hands-on training and real-world insights.
            </p>
            <p data-aos="fade-up">
              <span>Flexible Learning : </span> We offer both in-person and
              online courses to fit your schedule.{" "}
            </p>{" "}
            <p data-aos="fade-up">
              <span>Supportive Community : </span> Join a network of learners
              and professionals dedicated to growth and success.
            </p>{" "}
            <br />
            <p style={{ color: "blue" }} data-aos="fade-up">
              Join Us Today and take the first step towards a brighter, more
              tech-savvy future. We can't wait to help you achieve your goals!
            </p>
          </div>
        </div>
        <h2
          style={{
            padding: "10px",
            textTransform: "uppercase",
            textAlign: "center",
            fontWeight: "700",
            background: "rgb(8, 75, 130)",
            color: "white",
            margin: "0 30px",
          }}
          data-aos="fade-up"
        >
          Gallary
        </h2>
        <div data-aos="fade-up">
          <HomeCarousel />
        </div>

        <div className="h-students-sec" data-aos="fade-up">
          <h2
            style={{
              padding: "10px 0px",
              color: "white",
              fontWeight: "550",
              background: "rgb(8, 75, 130)",
              margin: "0 30px",
            }}
          >
            OUR STUDENTS
          </h2>
          <StudentRecord />
        </div>
      </div>
    </Layout>
  );
}
