import React, { useRef, useState } from "react";
import "../../../assets/css/admin.css";
import axios from "axios";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import Certificate from "../../../Components/Certificate";

const GenCertificate = () => {
  const [printBtn, setprintBtn] = useState(false);
  const [certificateData, setcertificateData] = useState({});
  const [certificate, setcertificate] = useState({});

  const generateCertificate = async () => {
    try {
      const {
        name,
        studentID,
        father_Name,
        mother_Name,
        dob,
        course_Title,
        grade,
        start_Date,
        end_Date,
      } = certificate;

      if (
        !name ||
        !studentID ||
        !father_Name ||
        !mother_Name ||
        !dob ||
        !course_Title
      ) {
        return toast.warn("Enter all details");
      }
      const { data } = await axios.post(`/v1/certificate/add-new-certificate`, {
        name,
        studentID,
        father_Name,
        mother_Name,
        dob,
        course_Title,
        grade,
        start_Date,
        end_Date,
        issued_Date: Date(),
      });
      if (data?.success) {
        toast.success(data?.message);
        setprintBtn(true);
        setcertificateData(data?.details);
        return;
      }
      toast.error(data?.message);
    } catch (error) {
      console.log("ERROR IN GETTING COURSES DATA:- " + error);
      toast.error("Something went wrong!!");
    }
  };

  const componentRef = useRef();
  return (
    <div className="gen-report-card-page">
      <hr />
      <h2>Personal Details</h2>
      <hr />
      <div className="personal-details">
        <input
          type="number"
          placeholder="Student ID"
          onChange={(e) =>
            setcertificate({
              ...certificate,
              studentID: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="Student Name"
          onChange={(e) =>
            setcertificate({
              ...certificate,
              name: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="father Name"
          onChange={(e) =>
            setcertificate({
              ...certificate,
              father_Name: e.target.value,
            })
          }
        />
        <input
          type="text"
          placeholder="mother Name"
          onChange={(e) =>
            setcertificate({
              ...certificate,
              mother_Name: e.target.value,
            })
          }
        />
        <div>
          <label htmlFor="">DOB</label>
          <input
            type="date"
            placeholder="dob"
            onChange={(e) =>
              setcertificate({
                ...certificate,
                dob: e.target.value,
              })
            }
            style={{ width: "250px" }}
          />
        </div>
      </div>
      <hr />
      <h2>Course Details</h2>
      <hr />
      <div className="personal-details">
        <div style={{ width: "100%" }}>
          <input
            type="text"
            placeholder="Course Name"
            onChange={(e) =>
              setcertificate({
                ...certificate,
                course_Title: e.target.value.toUpperCase(),
              })
            }
            style={{ width: "55%" }}
          />
          <input
            type="text"
            placeholder="Grade"
            onChange={(e) =>
              setcertificate({
                ...certificate,
                grade: e.target.value,
              })
            }
            style={{ width: "40%" }}
          />
        </div>
        <div style={{ width: "100%", fontWeight: "600" }}>
          <div>
            <label htmlFor="">Course Start Date</label>
            <input
              type="date"
              placeholder="course start date"
              onChange={(e) =>
                setcertificate({
                  ...certificate,
                  start_Date: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label htmlFor="">Course Start Date</label>

            <input
              type="date"
              placeholder="course end date"
              onChange={(e) =>
                setcertificate({
                  ...certificate,
                  end_Date: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "20px", textAlign: "right" }}>
        <button
          className="btn"
          style={{ backgroundColor: "blue", width: "200px" }}
          onClick={generateCertificate}
        >
          Submit{" "}
          <i
            className="fa-solid fa-arrow-up-right-from-square"
            style={{ marginLeft: "6px" }}
          />{" "}
        </button>
      </div>

      <div style={{ display: `${printBtn ? "block" : "none"}` }}>
        <div>
          <ReactToPrint
            trigger={() => (
              <button
                className="btn"
                style={{ width: "250px", background: "green" }}
              >
                Print Certificate
                <i
                  className="fa-solid fa-print"
                  style={{ marginLeft: "6px" }}
                />
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <Certificate ref={componentRef} data={certificateData} />
      </div>
    </div>
  );
};

export default GenCertificate;
