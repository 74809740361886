import React from "react";
import "../assets/css/header.css";
import Navbar from "./Navbar";


export default function Header() {


  return (
    <>
      <header>
        <div className="acc-header">
          <h1 className="acc-header-heading">
            <span id="header-span-1">AKRITI</span>{" "}
            <span id="header-span-1">COMPUTER</span>{" "}
            <span id="header-span-1">CENTER</span>
          </h1>
        </div>
        <h2 className="acc-header-bottom">
          Admission open for HTML, CSS, JavaScript, Reactjs, Expressjs, Nodejs,
          MongoDB, O-Level, ADCA, DCA, Tally, CCA, DTP
        </h2>
        <Navbar />
      </header>
    </>
  );
}
