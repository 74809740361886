import React, { forwardRef } from "react";
import "../assets/css/components.css";

const Certificate = forwardRef(({ data }, ref) => {
  return (
    <div className="marksheet-comp" ref={ref}>
      <h1>akriti computer center</h1>
      <div className="center-address">
        <p>Rajediha bazar, sant Kabir nagar</p>
        <p>Uttar Pradesh, india</p>
        <div style={{ paddingBottom: "10px" }}>
          Website: www.akriticomputer.in
        </div>
      </div>
      <hr />
      <h2
        style={{
          margin: "0 10px",
          background: "gray",
          padding: "7px",
          textAlign: "center",
          textTransform: "uppercase",
          color: "white",
        }}
      >
        certificate of completion
      </h2>
      <div className="cert-no">
        <div>Certificate No : {data?.certificate_No}</div>
        <div>Reg id : {data?.studentID}</div>
      </div>
      <div className="certificate-details">
        This is certify that Mr./ Miss <span>{data?.name}</span> s/o, d/o{" "}
        <span>{data?.father_Name}</span>
        and <span>{data?.mother_Name}</span> has successfully completed the
        course <span>{data?.course_Title}</span> from{" "}
        <span>{data?.start_Date}</span> to <span>{data?.end_Date}</span> and
        secured <span>{data?.grade}</span> grade.
      </div>
      <div className="student-personal-details"></div>
      <div className="center-director">
        <div style={{ color: "darkred" }}>
          Issue Date : {data?.issued_Date?.split("T")[0]}
        </div>
        <div>
          <p>RAMA KUMAR</p>
          <p>DIRECTOR</p>
        </div>
      </div>
    </div>
  );
});

export default Certificate;
