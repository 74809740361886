import React from 'react'

const AdminReport = () => {
  return (
    <div>
      Admin Report
    </div>
  )
}

export default AdminReport
