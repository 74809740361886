import React from "react";

const StudentReportCard = () => {
  return (
    <div>
      studentReportCard studentReportCardstudentReportCard
      studentReportCardstudentReportCard studentReportCard
    </div>
  );
};

export default StudentReportCard;
