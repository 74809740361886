import React, { useState } from "react";
import "../assets/css/components.css";
import { toast } from "react-toastify";
import axios from "axios";
import Sppinner from "../Animations/Sppinner";

const AddNewCourse = ({ setAddCourse }) => {
  const [courseData, setCourseData] = useState({});
  const [image, setImage] = useState();
  const [animation, setAnimation] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.toUpperCase();
    setCourseData({ ...courseData, [name]: value });
  };

  const sendData = async (e) => {
    e.preventDefault();
    const { course_Title, course_Fee, course_Duration, course_Category } =
      courseData;

    if (!course_Title || !course_Fee || !course_Duration || !course_Category) {
      return toast.warn("Enter all details");
    }
    if (!image) {
      return toast.warn("Upload Image");
    }
    const formData = new FormData();
    formData.append("course_Category", course_Category);
    formData.append("course_Title", course_Title);
    formData.append("course_Duration", course_Duration);
    formData.append("course_Fee", course_Fee);
    formData.append("course_Image", image);
    try {
      setAnimation(true);
      const { data } = await axios.post("/v1/course/add-new-course", formData);
      setAnimation(false);
      if (data?.success) {
        toast.success(data?.message);
        setAddCourse(false)
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      setAnimation(false);
    }
  };

  return (
    <>
      <div className="add-new-course-comp">
        <div style={{ display: `${animation ? "block" : "none"}` }}>
          <Sppinner />
        </div>
        <form style={{ display: `${!animation ? "block" : "none"}` }}>
          <h2>ADD NEW COURSE</h2>
          <div>
            <select name="course_Category" onChange={handleChange} value={courseData.course_Category}>
              <option value="">--- Choose Course Category ---</option>
              <option value="WEB DEVELOPMENT">Web Development</option>
              <option value="SOFTWARE DEVELOPMENT">Software Engineering</option>
              <option value="PROGRAMMING">Programming Courses</option>
              <option value="MICROSOFT COURSE">Microsoft Office</option>
              <option value="DIPLOMA COURSE">Diploma Courses</option>
              <option value="BACHELOR COURSE">Bachelor Courses</option>
              <option value="TALLY">Tally</option>
              <option value="BASIC COMPUTER COURSE">Basic Computer Courses</option>
              <option value="DIGITAL MARKETING">Digital Marketing</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              placeholder="Course Title"
              name="course_Title"
              value={courseData.course_Title}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="Number"
              placeholder="Course Duration in Months"
              name="course_Duration"
              value={courseData.course_Duration}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="Number"
              placeholder="Course Fee"
              name="course_Fee"
              value={courseData.course_Fee}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="c-img">Upload Course Image</label>
            <input
              type="file"
              id="c-img"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              className="btn"
              style={{ background: "red" }}
              onClick={(e) => {
                e.preventDefault();
                setAddCourse(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn"
              style={{ background: "blue" }}
              onClick={sendData}
            >
              <span>Add Course</span>
              <i
                className="fa-solid fa-arrow-up-right-from-square"
                style={{ marginLeft: "8px" }}
              />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewCourse;
