import React, { forwardRef } from "react";
import "../assets/css/components.css";

const Marksheet = forwardRef(({ data }, ref) => {
  return (
    <div className="marksheet-comp" ref={ref}>
      <h1>akriti computer center</h1>
      <div className="center-address">
        <p>Rajediha bazar, sant Kabir nagar</p>
        <p>Uttar Pradesh, india</p>
        <div style={{ paddingBottom: "10px" }}>
          Website: www.akriticomputer.in
        </div>
      </div>
      <hr />
      <div className="student-personal-details">
        <div>
          <div>SL No.</div>
          <div>Name </div>
          <div>father Name </div>
          <div>mother Name </div>
          <div>DOb </div>
        </div>
        <div style={{ padding: "0 30px" }}>
          <div>:</div>
          <div>:</div>
          <div>:</div>
          <div>:</div>
          <div>:</div>
        </div>

        <div>
          <div>{data?.marksheet_No}</div>
          <div>{data?.name}</div>
          <div>{data?.father_Name}</div>
          <div>{data?.mother_Name}</div>
          <div>{data?.dob}</div>
        </div>
      </div>{" "}
      <div className="mark-details">
        <h2>Marks Details</h2>
        <table border={"2px solid"} style={{ width: "100%", }}>
          <tr>
            <th>SNo.</th>
            <th>Subject Name</th>
            <th>Total Mark</th>
            <th>Obtained Mark</th>
          </tr>
          {data?.mark_Details.map((item, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{item?.subject_Name}</td>
              <td>{item?.total_Mark}</td>
              <td>{item?.obtained_Mark}</td>
            </tr>
          ))}
        </table>
        <div className="course-details">
          <div>
            <div>Course </div>
            <div>Course Duration </div>
            <div>Total Marks </div>
            <div>Obtained Marks </div>
            <div>Percentage </div>
            <div>Grade</div>
          </div>
          <div style={{ padding: "0 30px" }}>
            <div>:</div>
            <div>:</div>
            <div>:</div>
            <div>:</div>
            <div>:</div>
            <div>:</div>
          </div>
          <div>
            <div> {data?.course_Title}</div>
            <div>{data?.course_Duration}</div>
            <div> {data?.total_Marks}</div>
            <div>{data?.obtained_Marks}</div>
            <div> {((data?.obtained_Marks / data?.total_Marks) * 100).toFixed(2)}%</div>
            <div>{data?.grade}</div>
          </div>
        </div>
      </div>
      <div className="center-director">
        <div style={{color:"darkred"}}>Issue Date : {data?.issued_Date.split("T")[0]}</div>
        <div>
          <p>RAMA KUMAR</p>
          <p>DIRECTOR</p>
        </div>
      </div>
    </div>
  );
});

export default Marksheet;
